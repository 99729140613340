import { DateTime } from "luxon"
import * as yup from "yup"
import { email_or_phone } from "../auth/auth-validation"
import { AccountRoles } from "@/types"

export const partnershipAdValidationSchema = yup.object().shape({
  start_at: yup
    .string()
    .test("length", "Please select start at", (value) => {
      return typeof value === "string" && value.length === 10 && /\d\d\d\d-\d\d-\d\d/.test(value)
    })
    .required("Duration is required"),
  end_at: yup.mixed().test("length", "End at cannot be in the past", (value) => {
    return (
      value === null ||
      value === undefined ||
      (typeof value === "string" && value > DateTime.now().toFormat("yyyy-MM-dd"))
    )
  }),
  link: yup
    .string()
    .matches(
      /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
      "Incorrect link",
    )
    .required("Link is required"),
  image_src: yup
    .mixed()
    .required("Image is required")
    .test("size", "The file is too large", (image) => {
      return typeof image === "string" || (image && image.size <= 5000000) // 5MB
    })
    .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (image) => {
      return typeof image === "string" || (image && ["image/jpeg", "image/jpg", "image/png"].includes(image?.type))
    }),
})

export const partnershipInviteMemberValidationSchema = yup.object().shape({
  email_or_phone,
  role: yup.string().required().oneOf([AccountRoles.PARTNERSHIP_MEMBER, AccountRoles.PARTNERSHIP_OWNER]),
})
